import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "../assets/vendor/nucleo/css/nucleo.css";
import "../assets/vendor/font-awesome/css/font-awesome.min.css";
import "../assets/css/argon-design-system-react.css";
import "../index.css";
import "../assets/css/responsive.css";

import routes from "../component/config/Router";
import { base_url } from "../component/config/Url";

class index extends Component {
  render() {
    return (
      <>

        <Router basename={base_url()}>
          <React.Suspense fallback>
            <Switch>
              {routes.map((url, i) => {
                return url.layout ? (
                  <Route
                    key={i}
                    path={url.path}
                    exact={url.exact}
                    name={url.name}
                    render={(props) => <url.layout {...props} />}
                  />
                ) :  <Redirect from="/" to="/notfound" />;
              })}
              <Redirect from="/" to="/notfound" />
            </Switch>
          </React.Suspense>
        </Router>
      </>
    );
  }
}

export default index;
