const defaultSate = {
  restParticipants: {},
  codeParticipants: 500,
  messageParticipants: "",
  loadingPage: true,
  loadingSend: false,
  detailParcitipants: [],
  updated: false,
  before: false,
};

let response = {};

const Participants_lib = (state = defaultSate, action = {}) => {
  switch (action.type) {
    case "SUCCESS_PARTICIPANTS":
      response = {
        ...state,
        restParticipants: action.data ? action.data : {},
        detailParcitipants: action.data ? action.data : {},
        codeParticipants: action.code ? action.code : 500,
        messageParticipants: action.message ? action.message : "",
        loadingPage: false,
        loadingSend: false,
      };
      break;
    case "ERROR_PARTICIPANTS":
      response = {
        ...state,
        loadingPage: false,
      };
      break;
    case "UPDATE_PARTICIPANTS":
      response = {
        ...state,
        loadingSend: false,
        detailParcitipants: action.data ? action.data : {},
        updated: action.updated ? action.updated : false,
        before: action.before ? action.before : true,
      };
      break;
    case "LOADING_PARTICIPANTS":
      response = {
        ...state,
        loadingSend: true,
      };
      break;
    default:
      response = {
        ...state,
      };
  }

  return response;
};

export default Participants_lib;
