import { act } from "react-dom/test-utils";

const defaultState = {
  restGame: {},
  messageGame: "",
  codeGame: 500,
  loadingSend: false,
  loadingPage: true,
  id: "",
  organizer: false,
  participants: {},
  data: [],
  headerGame: {},
};

let response = {};

const Start_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_GAME":
      response = {
        ...state,
        codeGame: action.code ? action.code : 500,
        id: action.id ? action.id : "",
        loadingSend: false,
        headerGame: action.data ? action.data : {},
      };
      break;
    case "PROFIL_GAME":
      response = {
        ...state,
        codeGame: action.code ? action.code : 500,
        messageGame: action.message ? action.message : "",
        loadingPage: false,
        loadingSend: false,
        organizer: action.organizer ? true : false,
        participants: action.participants ? action.participants : {},
        data: action.data ? action.data : [],
      };
      break;
    case "ERROR_GAME":
      response = {
        ...state,
        loadingSend: false,
      };
      break;
    case "LOADING_START":
      response = {
        ...state,
        loadingSend: true,
      };
      break;
    case "UPDATE":
      response = {
        ...state,
        codeGame: action.code ? action.code : 500,
        id: action.id ? action.id : "",
        loadingSend: false,
        data: action.data ? { ...state.data, ...action.data } : {},
      };
      break;
    case "RELOAD_GAME":
      response = {
        ...state,
        participants: action.participants ? action.participants : {},
      };

      break;
    default:
      response = {
        ...state,
      };
  }

  return response;
};

export default Start_lib;
