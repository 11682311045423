const defaultState = {
    restContents : [],
    'welcome-message' : {},
    'tag-line' : {},
    codeContens: 500,
    loadingSend: true,
    loadingPage: true
}

let response = {};

export const Content_lib = (state = defaultState, action ={}) => {
    switch(action.type){
        case 'SUCCESS_CONTENTS':
            response = {
                ...state,
                restContents: action.data ? action.data : [],
                codeContens: action.code ? action.code : 500,
                loadingPage: false
            }
            break;
        case 'SUCCESS_WELCOME_MESSAGE':
            response = {
                ...state,
                'welcome-message' : action.data ? action.data : {},
                loadingPage: false
            }
            break;
        case 'SUCCESS_TAG_LINE':
            response = {
                ...state,
                'tag-line' : action.data ? action.data : {},
                loadingPage: false
            }
            break;
        case 'ERROR_CONTENTS':
        case 'ERROR_WELCOME_MESSAGE':
        case 'ERROR_TAG_LINE':
            response = {
                ...state,
                loadingPage: false,
            };
            break;
        default: 
            response = {
                ...state,
            }
    }
    return response;
}

export default Content_lib;