import React from "react";
import { base_url } from "./Url";
// layout
const Www = React.lazy(() => import("../../layouts/Www"));
const Game = React.lazy(() => import("../../layouts/Game"));
const User = React.lazy(() => import("../../layouts/User"));
// pages
const LandingPage = React.lazy(() => import("../../pages/Landing"));
const GameStart = React.lazy(() => import("../../pages/game/Start"));
// const GameSanta = React.lazy(() => import("../../pages/game/Santa"));
const GameSupper = React.lazy(() => import("../../pages/game/Supper"));
// const GameDinner = React.lazy(() => import("../../pages/game/Dinner"));
// const Profil = React.lazy(() => import('../../pages/user/Profil'))
const Profile = React.lazy(() => import("../../pages/game/Profile"));
// const Drawnpage = React.lazy(() => import('../../pages/user/Drawnpage'))
const Drawnpage = React.lazy(() => import("../../pages/game/Drawnpage"));
const Message = React.lazy(() => import("../../pages/user/Message"));
const MessageError = React.lazy(() => import("../../pages/user/MessageError"));
const GameProfile = React.lazy(() => import("../../pages/game/GameProfile"));

const Router = [
  {
    path: base_url("/"),
    exact: true,
    name: "Game Start",
    layout: Www,
    component: LandingPage,
  },
  {
    path: base_url("game/start/:gameName/:gameID"),
    exact: true,
    name: "Game Start",
    layout: Game,
    component: GameStart,
  },

  {
    // :gameID/:participantID
    path: base_url("profile/:participantID"),
    exact: true,
    name: "Game Profil",
    component: Profile,
    layout: User,
  },
  {
    // :gameID/:participantID
    path: base_url("game/profile/:gameID/:participantID"),
    exact: true,
    name: "Game Profil",
    component: GameProfile,
    layout: User,
  },
  {
    // :participantID
    path: base_url("game/drawnpage/:participantID"),
    exact: true,
    name: "Drawn page",
    component: Drawnpage,
    layout: User,
  },
  {
    path: base_url("game/message/:gameID"),
    exact: true,
    name: "Drawn page",
    component: Message,
    layout: User,
  },
  {
    path: base_url("game/message-error"),
    exact: true,
    name: "Drawn page",
    component: MessageError,
    layout: User,
  },
  {
    path: base_url("game/:gameName/"),
    exact: true,
    name: "Game Start",
    layout: Game,
    component: GameSupper,
  },
  {
    path: base_url("game/:gameName/:gameID"),
    exact: true,
    name: "Game Start",
    layout: Game,
    component: GameSupper,
  },
  {
    path: base_url("notfound"),
    exact: true,
    name: "Drawn page",
    component: MessageError,
    layout: User,
  },
];

export default Router;
