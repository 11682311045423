const initialState = {
  modal_1: false,
  modal_2: false,
  modal_3: false,
  modal_4: false,
  modal_5: false,
};

let response = {};

 const Modal_lib = (state = initialState, action = {}) => {
  switch (action.type) {
    case "MODAL_SUCCESS":
      response = {
        ...state,
        [action.modal ? action.modal : "modal_1"]: action.open
          ? action.open
          : false,
      };
      break;
    case "ERROR_MODAL":
      response = {
        ...state,
      };
      break;
    default:
      return state;
  }

  return response;
};

export default Modal_lib