const defaultState = {
  restWistlist: [],
  codeWistlist: 500,
  messageWistlist: "",
  loadingPage: true,
  loadingSend: false,
  newWistlist: 0,
  dietary_restrictions: [],
};

let response = {};

const Wistlist_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_WISTLIST":
      response = {
        ...state,
        codeWistlist: action.code ? action.code : 500,
        newWistlist: state.newWistlist + 1,
        loadingSend:false
      };
      break;
    case "DATA_WISTLIST":
      response = {
        ...state,
        restWistlist: action.data ? action.data : [],
        dietary_restrictions: action.dietary_restrictions
          ? action.dietary_restrictions
          : [],
        loadingPage: false,
        loadingSend:false
      };

      break;
    case "ERROR_WISTLIST":
      response = {
        ...state,
      };
      break;
    case "LOADING_WISHLIST":
      response = {
        ...state,
        codeWistlist: 500,
        loadingSend:true
      };
      break;
    default:
      response = {
        ...state,
        codeWistlist: action.code ? action.code : 500,
        loadingSend:false
      };
      break
  }

  return response;
};

export default Wistlist_lib;
