const defaultState = {
  restTypeofgame: [],
  messageTypeofgame: "",
  codeTypeofgame: 500,
  loadingSend: true,
  loadingPage: true,
};

let response = {};

export const Typeofgame_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_TYPEOFGAME":
      response = {
        ...state,
        restTypeofgame: action.data ? action.data : [],
        codeTypeofgame: action.code ? action.code : 500,
        messageTypeofgame: action.message ? action.message : "",
        loadingSend: false,
        loadingPage: false,
      };

      break;
    case "DETAIL_TYPEOFGAME":
      response = {
        ...state,
        detailsTypeofgame: action.data ? action.data : {},
        codeTypeofgame: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        messageTypeofgame: action.message ? action.message : "",
      };
      break;
    case "ERROR_TYPEOFGAME":
      response = {
        ...state,
        loadingPage: false,
      };
      break;
    default:
      response = {
        ...state,
      };
  }

  return response;
};

export default Typeofgame_lib;
