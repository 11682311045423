
const path = "/";

let basename_path = "";
var url = window.location.pathname.toLowerCase();

if (url.indexOf("build") === -1) {
  // If string(react) not available
  basename_path = "/";
} else {
  var array = url.split("react");
  
  basename_path = array[0] + "react/";
}
export const base_url = (param = "") => {
  return path + param;
};
